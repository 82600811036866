import React, { useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Page from '../components/Page';
import LoginForm from '../components/LoginForm';
import Header from '../components/Header';
import Container from '../components/Container';
import Dashboard from '../components/Dashboard';
import { clearLocalStorage } from '../utils/localStorage';
import { hasToken } from '../utils/token';
import { rem } from '../utils/styleHelpers';
import Notification from '../components/Notification';

const useStyles = makeStyles(
  {
    content: {
      display: 'flex',
      justifyContent: 'center',
      margin: rem(50),
      marginTop: rem(100),
    },
    login: {
      width: 'max-content',
      marginTop: rem(150),
      outline: 'none',
    },
    pageTitle: {
      width: '100%',
    },
    welcomeText: {
      marginLeft: rem(10),
    },
    loading: {
      width: '100%',
      textAlign: 'center',
      marginTop: rem(150),
    },
  },
  { name: 'Home' }
);

export default function Home() {
  const styles = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loginHelperText, setLoginHelperText] = useState('');
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSessionExpired(
        localStorage.getItem('sessionExpired')
          ? localStorage.getItem('sessionExpired') === 'true'
          : false
      );
      setLoggedIn(hasToken());
      if (loggedIn) {
        setUsername(localStorage.getItem('givenName'));
        setIsAdmin(localStorage.getItem('superUser') === 'true');
      }
      setLoading(false);
    }
    return () => {
      isMounted = false;
    };
  }, [loggedIn, isAdmin, username]);

  const handleLogin = (userData) => {
    if (userData.auth) {
      localStorage.clear();
      localStorage.setItem('userToken', userData.token);
      localStorage.setItem('givenName', userData.givenName);
      localStorage.setItem('superUser', userData.superUser);
      setLoginHelperText('');
      setLoginError(false);
      clearLocalStorage();
    } else {
      const msg = userData.message ? userData.message : 'Bad Credentials';
      setLoginHelperText(msg);
      setLoginError(true);
      localStorage.clear();
    }
    setLoggedIn(hasToken());
    setLoading(false);
  };

  const resetLoginErrors = () => {
    setLoginHelperText('');
    setLoginError(false);
  };

  return (
    <Page>
      <Header loggedIn={loggedIn} isAdmin={isAdmin} />
      {loading ? (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      ) : (
        <Container>
          {!loggedIn ? (
            <div>
              <LoginForm
                handleLogin={handleLogin}
                setLoader={() => setLoading(true)}
                loginError={loginError}
                loginHelperText={loginHelperText}
                resetLoginErrors={resetLoginErrors}
              />
            </div>
          ) : (
            <div className={styles.pageTitle}>
              <Typography variant="h4" className={styles.welcomeText}>
                Welcome to <span style={{ color: '#85BF31' }}>Lead</span>
                <span style={{ color: '#9FA1A4' }}>Chat</span> Rating {username}
              </Typography>
              <Divider />
              <Dashboard username={username} isAdmin={isAdmin} />
            </div>
          )}
        </Container>
      )}
      {sessionExpired && (
        <Notification error={sessionExpired} duration={500000} />
      )}
    </Page>
  );
}
