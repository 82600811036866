import React from 'react';
import { IconButton } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import ChatIcon from '@material-ui/icons/ChatBubble';
import AddIcon from '@material-ui/icons/AddBox';
import { Person, PersonAdd } from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Logo from '../images/leadchat-logo--300px.png';
import { colors } from '../utils/colors';
import { rem, boxShadowOn } from '../utils/styleHelpers';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      width: '100%',
      zIndex: 99,
      height: rem(75),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: boxShadowOn,
    },
    logo: {
      width: rem(200),
      paddingLeft: rem(40),
    },
    logout: { marginRight: rem(40), outline: 'none' },
    links: { display: 'flex' },
    emptyDiv: {
      width: rem(200),
    },
    active: {
      background: theme.palette.primary.main,
      padding: rem(10),
    },
    userIcon: {
      marginRight: '20px',
    },
  }),
  { name: 'Header' }
);

const StyledLinks = withStyles((theme) => ({
  root: {
    color: colors.gray1,
    margin: `${rem(0)} ${rem(10)}`,
    backgroundColor: colors.gray6,
    borderRadius: rem(5),
    '&:hover': {
      '& div': {
        transition: 'all 100ms',
        color: theme.palette.primary.main,
      },
    },
    '& div': {
      backgroundColor: colors.gray6,
      '& svg': {
        width: rem(20),
        height: rem(20),
      },
    },
    '&.active': {
      '& div': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiChip-label': {
      padding: `${rem(1)} ${rem(12)}`,
    },
  },
}))(Chip);

export default function Header({ loggedIn, isAdmin }) {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const logout = () => {
    localStorage.clear();
    window.location.replace('/');
  };

  const editUser = () => {
    window.location.replace('/editUser');
  };

  const getActive = (path) => {
    const currentPath = window.location.pathname;
    if (path === currentPath) return 'active';
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper className={styles.root} elevation={0}>
      <img className={styles.logo} src={Logo} alt="LeadChat Logo" />
      <div className={styles.links}>
        {loggedIn && !isAdmin ? (
          <div>
            <StyledLinks
              className={getActive('/')}
              component="a"
              href="/"
              label="Home"
              avatar={
                <Avatar>
                  <HomeIcon />
                </Avatar>
              }
            />
            <StyledLinks
              className={getActive('/chats')}
              component="a"
              href="/chats"
              label="All Chats"
              avatar={
                <Avatar>
                  <ChatIcon />
                </Avatar>
              }
            />
          </div>
        ) : loggedIn && isAdmin ? (
          <div>
            <StyledLinks
              className={getActive('/')}
              component="a"
              href="/"
              label="Home"
              avatar={
                <Avatar>
                  <HomeIcon />
                </Avatar>
              }
            />
            <StyledLinks
              className={getActive('/chats')}
              component="a"
              href="/chats"
              label="All Chats"
              avatar={
                <Avatar>
                  <ChatIcon />
                </Avatar>
              }
            />
            <StyledLinks
              className={getActive('/addchat')}
              component="a"
              label="Add Chats"
              href="/addchat"
              avatar={
                <Avatar>
                  <AddIcon />
                </Avatar>
              }
            />
            <StyledLinks
              className={getActive('/signin')}
              component="a"
              href="/signin"
              label="Create User"
              avatar={
                <Avatar>
                  <PersonAdd />
                </Avatar>
              }
            />
          </div>
        ) : null}
      </div>
      {loggedIn ? (
        <div>
          <IconButton
            aria-haspopup="true"
            onClick={handleClick}
            className={styles.userIcon}
            children={
              <Avatar>
                <Person />
              </Avatar>
            }
          />
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={editUser}>Reset password</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </Paper>
  );
}
