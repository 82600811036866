const codeDescriptions = {
  afn:
    'Agents are expected to ask for the visitor\'s name within the first 3 responses in the chat. Note: The ideal response if the visitor says hi or hello = "Hi there! May I know who I am chatting with" OR if the visitor asks a question = "Let me check that for you, May I also know who I am chatting with"',
  acl:
    "IF the visitor type was a suitable lead - agents are expected to ask for lead details within the chat. Note: Agent focus should always be on correctly identifying if the visitor is a useful lead to the client, and then asking for their details. This includes asking for details when the visitor is enquiring only. Agents are expected to attempt to turn an enquiry into a lead.",
  cld:
    "Once the visitor has provided lead details the Agent is expected to CONFIRM the lead by using any of the shortcodes containing [name: phone: email:] within the chat.",
  tcc:
    "IF it was required - Agents must correctly tag the chat with x-lead when the visitor contact details have been collected.",
  fms:
    "Agents are expected to follow the mandatory question structure supplied in the FAQ for the client. Where no mandatory flow is provided in the FAQ - agents should ask for lead details of all visitors. Agents are expected to edit mandatory questions to fit the conversation.",
  upl:
    "Agents are expected to use professional language at all times. Conversation should follow correct English language spelling and grammar flow. If a spelling mistake occurs, agents are expected to correct themselves within the chat. If a visitor says a statement that is happy or sad, agents are expected to respond appropriately for the tone. e.g Visitor: ’My father died” Agent: ’I’m sorry to hear that” - free typing is encouraged here",
  pss:
    "Agents are expected to respond to all questions asked by the visitor. If the agent does not have the answer they must respond that they don’t have that information available. ",
  uvt:
    "Agents are expected to be able to understand the context of the visitors conversation and identify if the visitor is a lead or not.",
  pcf:
    "If a FAQ response is used the agent is expected to select the correct / most appropriate response. The agent is expected to edit the response to fit the conversation. Note: Agents should not paste large blocks of text unless all information is appropriate for the conversation. ",
};

export const getTootltipTitle = (item) => {
  switch (item) {
    case "AFN":
      return codeDescriptions.afn;
    case "ACL":
      return codeDescriptions.acl;
    case "CLD":
      return codeDescriptions.cld;
    case "TCC":
      return codeDescriptions.tcc;
    case "FMS":
      return codeDescriptions.fms;
    case "UPL":
      return codeDescriptions.upl;
    case "PSS":
      return codeDescriptions.pss;
    case "UVT":
      return codeDescriptions.uvt;
    case "PCF":
      return codeDescriptions.pcf;
    default:
      return "";
  }
};
