import React, { useState } from 'react';
import {
  makeStyles,
  InputLabel,
  FormControl,
  Select as MuiSelect,
} from '@material-ui/core';
import Fetch from '../utils/Fetch';
import { agentsEndpoint } from '../utils/endpoints';
import { rem } from '../utils/styleHelpers';

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      marginRight: rem(15),
      marginTop: rem(5),
      minWidth: 120,
    },
  }),
  { name: 'Select' }
);

export default function Select({ callback }) {
  const styles = useStyles();

  const [agentName, setAgentName] = useState('Overview');
  const [agents, loading] = Fetch(agentsEndpoint, 'get', {}) || [];

  const handleChange = (event) => {
    setAgentName(event.target.value);
    callback(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={styles.formControl}>
      <InputLabel htmlFor="outlined-name-native-simple">Select</InputLabel>
      {!loading && (
        <MuiSelect
          native
          value={agentName}
          onChange={handleChange}
          label="Select"
          inputProps={{
            name: 'agentName',
            id: 'outlined-name-native-simple',
          }}
        >
          <option>Overview</option>
          {agents.map(({ agentName }, index) => (
            <option value={agentName} key={index}>
              {agentName}
            </option>
          ))}
        </MuiSelect>
      )}
    </FormControl>
  );
}
