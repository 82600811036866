import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  makeStyles,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  Button,
  Typography,
  Divider,
  Paper,
  FormControl,
} from '@material-ui/core';
import Page from '../components/Page';
import Header from '../components/Header';
import Container from '../components/Container';
import axios from 'axios';
import moment from 'moment';
import { rem } from '../utils/styleHelpers';
import { hasToken } from '../utils/token';
import { clearLocalStorage } from '../utils/localStorage';
import ChatItem from '../components/ChatItem';
import { colors } from '../utils/colors';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    pageTitleContinaer: {
      width: '100%',
    },
    pageTitle: {
      marginLeft: rem(10),
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    chatsContainer: {
      minWidth: rem(600),
      maxWidth: rem(1000),
      padding: `${rem(0)} ${rem(40)}`,
      paddingTop: rem(10),
      margin: `${rem(30)} ${rem(0)}`,
    },
    selectContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: rem(600),
    },
    formControl: {
      marginRight: rem(15),
      marginTop: rem(5),
      minWidth: 120,
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      width: '100%',
    },
    sortWrapper: {
      display: 'flex',
      'justify-content': 'flex-end',
      width: '100%',
    },
  }),
  { name: 'AllChats' }
);

export default function AllChats() {
  const styles = useStyles();
  const chatsPerPage = 15;

  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);

  const [goHome, setGoHome] = useState(false);
  const [chats, setChats] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [sortAgentValue, setSortAgentValue] = useState('All');
  const [sortOrder, setSortOrder] = useState('All');
  const [loading, setloading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [username, setUsername] = useState('');
  const [userToken, setUserToken] = useState('');

  const [orderBysql, setOrderBysql] = useState(null);
  const [where, setWhere] = useState(null);
  const [agentSorted, setAgentSorted] = useState('All');

  useEffect(() => {
    let isMounted = true;
    setLoggedIn(hasToken());
    if (loggedIn) {
      setUsername(localStorage.getItem('givenName'));
      setIsAdmin(localStorage.getItem('superUser') === 'true');
      setUserToken(localStorage.getItem('userToken'));

      const getChats = async () => {
        if (isAdmin) {
          await axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}/api/allchats`,
              {
                pageCount,
                chatsPerPage,
                whereClouse: null,
                orderBy: null,
              },
              { headers: { 'x-access-token': userToken } }
            )
            .then((result) => {
              if (!isMounted) return null;
              setChats(result.data.chats);
              setHasMore(result.data.hasMore);
              setTimeout(() => setloading(false), 1000);
              return result;
            });
        } else if (!isAdmin) {
          await axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}/api/userchats`,
              { pageCount, chatsPerPage },
              { headers: { 'x-access-token': userToken } }
            )
            .then((result) => {
              if (!isMounted) return null;
              setChats(result.data.chats);
              setHasMore(result.data.hasMore);
              setTimeout(() => setloading(false), 1000);
              return result;
            });
        }
      };

      const getAgents = async () => {
        if (isAdmin) {
          await axios(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
            headers: { 'x-access-token': userToken },
          })
            .then((result) => {
              if (!isMounted) return null;
              setAgentsList(
                result.data.filter((user) => !user.superUser && user.givenName)
              );
              return result;
            })
            .catch((err) => {
              console.log(err.message);
              localStorage.clear();
              localStorage.setItem('sessionExpired', 'true');
              setGoHome(true);
            });
        }
      };

      getAgents();
      if (isAdmin !== null && pageCount === 0) {
        getChats();
      }

      clearLocalStorage();

      return () => {
        isMounted = false;
      };
    }
    // console.log(username, userToken);
    if (!hasToken()) setGoHome(true);
  }, [loggedIn, isAdmin, pageCount, userToken]);

  const getAllChats = async (
    pageCount,
    chatsPerPage,
    whereClouse = null,
    orderBy = null
  ) => {
    console.log(whereClouse);

    if (whereClouse.agentName === 'All') {
      delete whereClouse.agentName;
    }

    return await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/allchats`,
      {
        pageCount,
        chatsPerPage,
        whereClouse,
        orderBy,
      },
      { headers: { 'x-access-token': userToken } }
    );
  };

  const getUserChats = async (pageCount, chatsPerPage, user) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/userchats`,
      {
        pageCount,
        chatsPerPage,
        username: user,
      },
      { headers: { 'x-access-token': userToken } }
    );
  };

  const getChatColor = (rating) => {
    if (rating === null) return colors.gray4;
    if (rating === 100) return colors.success;
    if (rating === 75) return colors.warning;
    if (rating < 75) return colors.failure;
  };

  const handleAgentSort = async (event) => {
    setPageCount(0);
    let result;
    const sortValue = event.target.value;
    setSortAgentValue(sortValue);
    setUsername(sortValue);

    const whereProp = {
      ...where,
      agentName: sortValue,
    };
    setWhere(whereProp);

    setAgentSorted(sortValue);
    result = await getAllChats(pageCount, chatsPerPage, whereProp, orderBysql);
    setChats(result.data.chats);
    setHasMore(true);
    setloading(false);
  };

  const handleSortOrder = async (event) => {
    setPageCount(0);
    let result;
    const sortValue = event.target.value;
    setSortOrder(sortValue);

    if (sortValue === 'All') {
      const whereProp = {
        agentName: agentSorted,
      };
      setWhere(whereProp);
      setOrderBysql(null);

      result = await getAllChats(pageCount, chatsPerPage, whereProp);

      setChats(result.data.chats);
      setHasMore(true);
      setloading(false);
    } else if (sortValue === 'Group') {
      const whereProp = {
        agentName: agentSorted,
      };
      setWhere(whereProp);
      setOrderBysql(sortValue.toLowerCase());

      result = await getAllChats(
        pageCount,
        chatsPerPage,
        whereProp,
        sortValue.toLowerCase()
      );
      setChats(result.data.chats);
      setHasMore(true);
      setloading(false);
    } else if (sortValue === 'Rated') {
      const whereProp = {
        agentName: agentSorted,
        rated: true,
      };
      setWhere(whereProp);
      setOrderBysql(null);

      result = await getAllChats(pageCount, chatsPerPage, whereProp);
      setChats(result.data.chats);
      setHasMore(true);
      setloading(false);
    } else if (sortValue === 'Unrated') {
      const whereProp = {
        agentName: agentSorted,
        rated: false,
      };
      setWhere(whereProp);
      setOrderBysql(null);

      result = await getAllChats(pageCount, chatsPerPage, whereProp);
      setChats(result.data.chats);
      setHasMore(true);
      setloading(false);
    }
  };

  const getMoreChats = async () => {
    setPageCount((pageCount) => pageCount + 1);
    let result;
    if (isAdmin) {
      // if (sortAgentValue === 'All') {
      //   result = await getAllChats(pageCount + 1, chatsPerPage, null, null);
      //   setChats((chats) => chats.concat(result.data.chats));
      //   setHasMore(result.data.hasMore);
      // } else if (sortAgentValue === 'Group') {
      //   result = await getAllChats(pageCount + 1, chatsPerPage, orderBysql);
      //   // const chats = result.data.chats;
      //   // chats.sort((a, b) => (a.group > b.group ? 1 : -1));
      //   setChats((chats) => chats.concat(result.data.chats));
      //   setHasMore(result.data.hasMore);
      // } else if (sortAgentValue === 'Rated') {
      result = await getAllChats(
        pageCount + 1,
        chatsPerPage,
        where,
        orderBysql
      );
      let filteredChats = result.data.chats;
      // filteredChats = filteredChats.filter((chat) => chat.rated);
      setChats((chats) => chats.concat(filteredChats));
      setHasMore(result.data.hasMore);
      // } else {
      //   result = await getUserChats(pageCount + 1, chatsPerPage, agentSorted);
      //   setChats((chats) => chats.concat(result.data.chats));
      //   setHasMore(result.data.hasMore);
      // }
    }
  };

  return (
    <Page>
      {goHome ? <Redirect to="/" /> : null}
      <Header loggedIn={loggedIn} isAdmin={isAdmin} />
      <Container>
        <div className={styles.pageTitleContinaer}>
          <Typography variant="h4" className={styles.pageTitle}>
            <span style={{ color: '#85BF31' }}>Chats</span>{' '}
            <span style={{ color: '#9FA1A4' }}>Overview</span> Page
          </Typography>
          <Divider />
        </div>
        <div className={styles.contentContainer}>
          {isAdmin ? (
            <div className={styles.sortWrapper}>
              <div>
                <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel htmlFor="outlined-sort-native-simple">
                    Sort by
                  </InputLabel>
                  <Select
                    value={sortOrder}
                    onChange={handleSortOrder}
                    label="Sort by"
                    inputProps={{
                      name: 'sortOrder',
                      id: 'outlined-sort-native',
                    }}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'Group'}>Group</MenuItem>
                    <MenuItem value={'Rated'}>Rated</MenuItem>
                    <MenuItem value={'Unrated'}>Unrated</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel htmlFor="outlined-sort-native-simple">
                    Agent
                  </InputLabel>
                  <Select
                    value={sortAgentValue}
                    onChange={handleAgentSort}
                    label="Agent"
                    inputProps={{
                      name: 'agentName',
                      id: 'outlined-sort-native-simple',
                    }}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    {agentsList.map((agent, index) => {
                      return (
                        <MenuItem value={agent.agentName} key={index}>
                          {agent.agentName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : null}
          {loading ? (
            <CircularProgress />
          ) : (
            <Paper className={styles.chatsContainer}>
              {chats.map((chat, index) => {
                return (
                  <a
                    href={`/chats/${chat.id}`}
                    className={styles.link}
                    key={index}
                  >
                    <ChatItem
                      rating={chat.rating}
                      agent={chat.agentName}
                      date={moment(chat.createdAt).format('Do MMM YY')}
                      id={chat.livechatId}
                      group={chat.group}
                      ratingColor={getChatColor(chat.rating)}
                    />
                  </a>
                );
              })}
            </Paper>
          )}
          {hasMore && !loading ? (
            <Button
              className={styles.button}
              variant="outlined"
              color="primary"
              size="medium"
              onClick={getMoreChats}
            >
              Load More
            </Button>
          ) : !hasMore && !loading ? (
            <Typography variant="h5">No More Chats</Typography>
          ) : null}
        </div>
      </Container>
    </Page>
  );
}
