import React, { useMemo, useState, useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { globalThemeOptions } from './utils/theme';
import { hasToken } from './utils/token';
import './stylesheets/reset.css';
import './stylesheets/App.css';

function App() {
  const brandColor = '#85BF31';
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setLoggedIn(hasToken());
    const isSuperAdmin = localStorage.getItem('superUser')
      ? localStorage.getItem('superUser') === 'true'
      : false;
    setIsAdmin(isSuperAdmin);
  }, [loggedIn, isAdmin]);

  const theme = useMemo(() => {
    return createMuiTheme({
      ...globalThemeOptions,
      palette: {
        primary: {
          main: brandColor,
        },
      },
    });
  }, [brandColor]);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes loggedIn={loggedIn} isAdmin={isAdmin} />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
