import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { signin } from '../utils/user';
import {
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const GreenCheckbox = withStyles({
  root: {
    // color: 'black',
    '&$checked': {
      color: '#85BF31',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
});

export default function SigninForm(props) {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [superUser, setSuperUser] = useState(false);

  useEffect(() => {
    if (username !== '' && name !== '' && password !== '' && confirm !== '') {
      setDisabled(false);
    }

    if (password !== confirm && confirm !== '') {
      setError(true);
      setDisabled(true);
      setHelperText('Passwords must match!');
      return;
    }

    setError(false);
    setHelperText('');
    return () => {
      //   console.log();
    };
  }, [password, confirm, username, name]);

  const handleSignin = async () => {
    if (error) return;
    const user = {
      username,
      name,
      password,
      superUser,
    };
    const success = await signin(user, localStorage.getItem('userToken'));
    console.log(success);
    if (success) {
      setSuccess(true);
    } else {
      setFailed(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
    setFailed(false);
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Create Account Form
          </Typography>

          <div>
            <TextField
              type="text"
              label="Username"
              className={classes.input}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              type="text"
              label="Given Name"
              className={classes.input}
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              type="password"
              label="Password"
              className={classes.input}
              value={password}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <TextField
              type="password"
              className={classes.input}
              value={confirm}
              error={error}
              label="Confirm Password"
              variant="outlined"
              helperText={helperText}
              onChange={(e) => setConfirm(e.target.value)}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={superUser}
                  onChange={() => setSuperUser(!superUser)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="SuperUser"
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            disabled={disabled}
            onClick={async () => await handleSignin()}
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            Create Account
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={success}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success">
          User account created succesfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failed}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error">
          Failed to create a new user account!
        </Alert>
      </Snackbar>
    </div>
  );
}
