import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { css, jsx } from '@emotion/core';
import Button from '@material-ui/core/Button';

/**@jsx jsx */
export default function PrevNextButton() {
  const chatId = Number(
    window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  );

  const [pageNumber, setPageNumber] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isAgent, setIsAgent] = useState(false);

  useEffect(() => {
    const getUserType = async () => {
      const superUser = localStorage.getItem('superUser') === 'true';
      if (!superUser) {
        setIsAgent(true);
      }
    };
    getUserType();
  }, []);

  const handleRedirect = (direction) => {
    direction === 'next'
      ? setPageNumber(chatId + 1)
      : setPageNumber(chatId - 1);
    setRedirect(true);
  };

  return (
    <div
      css={css`
        margin-top: 2rem;
      `}
    >
      {!isAgent ? (
        <div>
          {redirect ? <Redirect to={`/chats/${pageNumber}`} /> : null}
          <Button
            style={{ margin: '0.5rem', marginBottom: '2rem' }}
            variant="outlined"
            color="primary"
            onClick={() => handleRedirect()}
          >
            Prev
          </Button>
          <Button
            style={{ margin: '0.5rem', marginBottom: '2rem' }}
            variant="outlined"
            color="primary"
            onClick={() => handleRedirect('next')}
          >
            Next
          </Button>
        </div>
      ) : null}
    </div>
  );
}
