const jwt = require("jwt-simple");

const getToken = (data) => {
  return jwt.encode(data, process.env.REACT_APP_JWT_SECRET);
};

const getData = (token) => {
  return jwt.decode(token, process.env.REACT_APP_JWT_SECRET);
};

export { getToken, getData };
