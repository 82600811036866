import axios from 'axios';
import { getData } from '../utils/encrypt';

export const getUserData = (data) => {
  const token = localStorage.getItem('userToken');
  if (token) return getData(token)[data];
  return data === 'givenName' ? '' : false;
};

export const changePass = async (user, userToken) => {
  if (!user) return { auth: false };

  const success = await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/user/change`,
      {
        oldPassword: user.oldPass,
        password: user.password,
      },
      {
        headers: { 'x-access-token': userToken },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      return false;
    });

  return success;
};

export const login = async (user) => {
  if (!user) return { auth: false };

  const { username, password } = user;

  const authO = await axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/login`, {
      username,
      password,
    })
    .then((res) => {
      if (res.data.message === 'Wrong Password') {
        return { auth: false, message: res.data.message };
      }
      if (res.data.message === 'User Not Found') {
        return { auth: false, message: res.data.message };
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return { auth: false, message: err.message };
    });

  return authO;
};

export const signin = async (user, userToken) => {
  if (!user) return false;

  let userExists = null;

  await axios(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
    headers: { 'x-access-token': userToken },
  })
    .then((result) => {
      userExists = result.data.find((u) => u.username === user.username);
      return result;
    })
    .catch((err) => {
      console.log(err.message);
    });

  if (userExists) return false;

  const { username, name, password, superUser } = user;

  const success = await axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/signin`,
      {
        username,
        name,
        password,
        superUser,
      },
      { headers: { 'x-access-token': userToken } }
    )
    .catch((err) => {
      console.log('ERRO signin: ', err);
    });
  return success;
};

export const userLogin = async (googleData) => {
  if (googleData.error) return false;
  const { givenName } = googleData.profileObj;
  const { googleId, accessToken } = googleData;
  await axios
    .post(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
      givenName,
      googleId,
      accessToken,
    })
    .then(async (res) => {
      // if user is found update user's accessToken
      if (res.data === 'User found') {
        await axios
          .put(`${process.env.REACT_APP_SERVER_URL}/api/users/update`, {
            googleId,
            accessToken,
          })
          .then((res) => {
            localStorage.setItem('userToken', res.data);
          });
      } else {
        localStorage.setItem('userToken', res.data);
      }
    })
    .catch((err) => {
      console.log('ERRO userLogin: ', err);
      throw new Error(
        'Login issue, please refresh the page or open a new browser window'
      );
    });
};

export const getUserChats = async (userToken, pageCount) => {
  return axios
    .post(
      `${process.env.REACT_APP_SERVER_URL}/api/userchats`,
      {
        pageCount,
      },
      { headers: { 'x-access-token': userToken } }
    )
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
};
