import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  Paper,
  Button,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Page from '../components/Page';
import Container from '../components/Container';
import Header from '../components/Header';
import { hasToken } from '../utils/token';
import { clearLocalStorage } from '../utils/localStorage';
import { rem } from '../utils/styleHelpers';
import { colors } from '../utils/colors';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    marginTop: rem(80),
  },
  paper: {
    padding: `${rem(20)} ${rem(20)}`,
    minWidth: rem(250),
  },
  form: {
    // border: `1px solid ${colors.lime}`,
    borderRadius: rem(5),
    padding: `${rem(0)} ${rem(10)}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '&:hover': {
      boxShadow: '0px 0px 15px lightgrey',
      // borderColor: fade(colors.lime, 0.5),
    },
  },
  button: {
    margin: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
    paddingBottom: rem(10),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 50,
  },
}));

export default function AddChat() {
  const styles = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [agentsList, setAgentsList] = useState([]);
  const [agent, setAgent] = useState('');
  const [chatId, setChatId] = useState('');
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [submitMessage, setSubmitMessage] = useState(0);
  const [errorMessage, setErrorMessage] = useState(0);
  const [reason, setReason] = useState('');
  const [feedback, setFeedback] = useState('');
  const [selected, setSelected] = useState(0);
  const [toHome, setToHome] = useState(false);
  const [user, setUsername] = useState('');
  const [isManualReason, setManualReason] = useState(false);
  const [userToken, setUserToken] = useState('');

  useEffect(() => {
    setLoggedIn(hasToken());
    if (loggedIn) {
      setUsername(localStorage.getItem('givenName'));
      setIsAdmin(localStorage.getItem('superUser'));
      setUserToken(localStorage.getItem('userToken'));

      const getAgents = async () => {
        if (hasToken()) {
          await axios(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
            headers: { 'x-access-token': userToken },
          })
            .then((result) => {
              setAgentsList(result.data.filter((user) => !user.superUser));
            })
            .catch((err) => {
              console.log(err.message);
              localStorage.clear();
              localStorage.setItem('sessionExpired', 'true');
              setToHome(true);
            });
        }
      };

      if (userToken !== '') getAgents();
      clearLocalStorage();
    }

    if (!hasToken()) setToHome(true);
  }, [loggedIn, userToken]);

  function handleAgent(event) {
    setAgent(event.target.value);
    setSelected((selected) => selected + 1);
    setHideSubmitButton(true);
    if (selected >= 3) {
      setHideSubmitButton(false);
    }
  }

  function handleReason(event) {
    if (event.target.value === 'manual') {
      setManualReason(true);
    } else {
      setReason(event.target.value);
      setSelected((selected) => selected + 1);
      setHideSubmitButton(true);
      if (selected >= 3) {
        setHideSubmitButton(false);
      }
    }
  }

  function handleFeedback(event) {
    setFeedback(event.target.value);
    setSelected((selected) => selected + 1);
    setHideSubmitButton(true);
    if (selected >= 3) {
      setHideSubmitButton(false);
    }
  }

  function getId(event) {
    setChatId(event.target.value);
    setHideSubmitButton(true);
    if (event.target.value.length === 10) {
      setSelected((selected) => selected + 1);
    }
    if (selected >= 3) {
      setHideSubmitButton(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const date = await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/allchats`,
        {
          pageCount: 0,
        },
        { headers: { 'x-access-token': userToken } }
      )
      .then((res) => res.data.chats[0].createdAt)
      .catch((err) => console.log(err));

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/chats`,
        {
          livechatId: chatId,
          agentName: agent.agentName,
          agentId: agent.id,
          reason: reason,
          feedbackVia: feedback,
          ratedBy: user,
          createdAt: date,
        },
        { headers: { 'x-access-token': userToken } }
      )
      .then((res) => {
        if (res.data === 'already in server') {
          setErrorMessage(100);
          alert('This chat already exists in the database');
          setTimeout(() => setErrorMessage(0), 2000);
          setAgent([]);
          setChatId('');
          setReason('');
          setFeedback('');
          setManualReason(false);
          setHideSubmitButton(true);
        } else {
          if (res.status === 201) {
            setAgent([]);
            setChatId('');
            setReason('');
            setFeedback('');
            setManualReason(false);
            setHideSubmitButton(true);
            setSubmitMessage(100);
            setTimeout(() => setSubmitMessage(0), 2000);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        setErrorMessage(100);
        setTimeout(() => setErrorMessage(0), 2000);
      });
  }

  return (
    <Page>
      {toHome ? <Redirect to="/" /> : null}
      <Header loggedIn={loggedIn} isAdmin={isAdmin} />
      <Container>
        <div className={styles.contentContainer}>
          <Paper className={styles.paper}>
            <Typography variant="h6" className={styles.title}>
              Add Chat Manually
            </Typography>
            <form
              className={styles.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <TextField
                id="outlined-with-placeholder"
                label="Add Chat"
                placeholder="Chat ID"
                margin="normal"
                variant="outlined"
                value={chatId}
                onChange={getId}
              />
              <FormControl variant="outlined" className={styles.formControl}>
                <InputLabel>Agent</InputLabel>
                <Select value={agent} onChange={handleAgent} label="Agent">
                  {agentsList.map((agent, index) => {
                    return (
                      <MenuItem value={agent} key={index}>
                        {agent.agentName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={styles.formControl}>
                {isManualReason ? (
                  <TextField
                    variant="outlined"
                    id="standard-basic"
                    label="Manual Reason"
                    onChange={(event) => setReason(event.target.value)}
                  />
                ) : (
                  <div className={styles.root} autoComplete="off">
                    <InputLabel>Reason</InputLabel>
                    <Select
                      value={reason}
                      onChange={handleReason}
                      label="Reason"
                    >
                      <MenuItem value={'FAQ / Mandatory Selection'}>
                        FAQ / Mandatory Selection
                      </MenuItem>
                      <MenuItem value={'Tone of Chat'}>Tone of Chat</MenuItem>
                      <MenuItem value={'Personalisation'}>
                        Personalisation
                      </MenuItem>
                      <MenuItem value={'Use of Language'}>
                        Use of Language
                      </MenuItem>
                      <MenuItem value={'Incorrect Tag'}>Incorrect Tag</MenuItem>
                      <MenuItem value={'Missed Opportunity'}>
                        Missed Opportunity
                      </MenuItem>
                      <MenuItem value={'Improvements'}>Improvements</MenuItem>
                      <MenuItem value={'Commendation'}>Commendation</MenuItem>
                      <MenuItem value={'General Scoring'}>
                        General Scoring
                      </MenuItem>
                      <MenuItem value={'Testing - not real'}>
                        Testing - not real
                      </MenuItem>
                      <MenuItem value="manual">Manual Reason</MenuItem>
                    </Select>
                  </div>
                )}
              </FormControl>
              <FormControl variant="outlined" className={styles.formControl}>
                <InputLabel>Feedback Via</InputLabel>
                <Select
                  value={feedback}
                  onChange={handleFeedback}
                  label="Feedback Via"
                >
                  <MenuItem value={'Client'}>Client</MenuItem>
                  <MenuItem value={'Agent'}>Agent</MenuItem>
                  <MenuItem value={'Account Manager'}>Account Manager</MenuItem>
                  <MenuItem value={'Sales/LC Internal'}>
                    Sales/LC Internal
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                className={styles.button}
                type="submit"
                disabled={hideSubmitButton}
              >
                Submit
              </Button>
              <Typography
                variant="h6"
                style={{
                  opacity: submitMessage,
                  textAlign: 'center',
                  color: colors.success,
                }}
              >
                Chat Submitted
              </Typography>
              <Typography
                variant="h6"
                style={{
                  opacity: errorMessage,
                  textAlign: 'center',
                  color: colors.failure,
                }}
              >
                Oops please try again!
              </Typography>
            </form>
          </Paper>
        </div>
      </Container>
    </Page>
  );
}
