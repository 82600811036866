import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import {
  AreaChart as ReAreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  // Tooltip,
} from 'recharts';
import { colors } from '../../utils/colors';
import Fetch from '../../utils/Fetch';
import { ratings } from '../../utils/endpoints';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
    },
    legendText: {
      color: colors.gray3,
      verticalAlign: 'middle',
    },
    loading: {
      marginTop: '20%',
    },
  }),
  { name: 'AreaChart' }
);

export default function AreaChart({ from, to, name }) {
  const styles = useStyles();
  const [ratingsReponse, loading] = Fetch(
    ratings,
    'post',
    { agentName: name, from, to },
    { 'x-access-token': localStorage.getItem('userToken') }
  );

  const data = ratingsReponse || [];

  const getLegendText = (value, entry) => {
    const getText = (value) => {
      switch (value) {
        case 'rating':
          return 'Key Performance';
        case 'ratingSkill':
          return 'Communication Skill';
        case 'ratingAverage':
          return 'Key Performance Average';
        case 'skillAverage':
          return 'Communication Skill Average';
        default:
          return '';
      }
    };
    return <span className={styles.legendText}>{getText(value)}</span>;
  };

  if (name === 'Overview')
    return (
      <div className={styles.root}>
        {loading ? (
          <CircularProgress className={styles.loading} />
        ) : (
          <ResponsiveContainer width={'95%'} height={'95%'}>
            <ReAreaChart
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 0"
                vertical={false}
                stroke={colors.gray5}
              />
              <XAxis
                dataKey="ratingDate"
                axisLine={false}
                tickLine={false}
                tickMargin={17}
                stroke={colors.gray4}
              />
              {/* <Tooltip /> */}
              <YAxis
                // domain={[50, 100]}
                axisLine={false}
                tickLine={false}
                tickMargin={15}
                stroke={colors.gray4}
              />
              <Legend
                formatter={getLegendText}
                verticalAlign="top"
                align="right"
                height={30}
                iconSize={15}
                iconType="circle"
              />
              <Area
                type="linear"
                dataKey="skillAverage"
                stroke={colors.turquoise}
                fill={colors.turquoise}
              />
              <Area
                type="linear"
                dataKey="ratingAverage"
                stroke={colors.lime}
                fill={colors.lime}
              />
            </ReAreaChart>
          </ResponsiveContainer>
        )}
      </div>
    );

  return (
    <div className={styles.root}>
      {loading ? (
        <CircularProgress className={styles.loading}></CircularProgress>
      ) : (
        <ResponsiveContainer width={'95%'} height={'95%'}>
          <ReAreaChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 0"
              vertical={false}
              stroke={colors.gray5}
            />
            <XAxis
              dataKey="ratingDate"
              axisLine={false}
              tickLine={false}
              tickMargin={17}
              stroke={colors.gray4}
            />
            {/* <Tooltip /> */}
            <YAxis
              domain={[50, 100]}
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              stroke={colors.gray4}
            />
            <Legend
              formatter={getLegendText}
              verticalAlign="top"
              align="right"
              height={30}
              iconSize={15}
              iconType="circle"
            />
            <Area
              type="monotone"
              dataKey="ratingSkill"
              stroke={colors.blue}
              fill={colors.blue}
            />
            <Area
              type="monotone"
              dataKey="rating"
              stroke={colors.red}
              fill={colors.red}
            />
          </ReAreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
