import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../utils/user';
import {
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
});

export default function LoginForm(props) {
  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    props.setLoader(true);
    const user = {
      username,
      password,
    };
    const userData = await login(user);
    props.handleLogin(userData);
  };

  const handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    if (username !== '' || password !== '') props.resetLoginErrors();
    // return false;
  }, [username, password, props]);

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Login Form
          </Typography>

          <div>
            <TextField
              error={props.loginError}
              type="text"
              variant="outlined"
              label="Username"
              className={classes.input}
              value={username}
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <TextField
              type="password"
              variant="outlined"
              label="Password"
              className={classes.input}
              value={password}
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) => setPassword(e.target.value)}
              helperText={props.loginHelperText}
              error={props.loginError}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            onClick={handleLogin}
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
