import React, { useState, useEffect } from 'react';
import { makeStyles, InputLabel, FormControl, Select } from '@material-ui/core';
import { rem } from '../utils/styleHelpers';

const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      marginRight: rem(15),
      marginTop: rem(5),
      minWidth: 120,
    },
  }),
  { name: 'Select' }
);

export default function SelectDates({
  callback,
  whichDate,
  existingDate,
  label,
  fetchedDate,
}) {
  const styles = useStyles();

  const [date, setDate] = useState('');

  const handleChange = (event) => {
    setDate(event.target.value);
    callback(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && fetchedDate !== null) {
      if (whichDate.from && existingDate === null) {
        setDate(fetchedDate[3]);
        callback(fetchedDate[3]);
      }
      if (whichDate.to && existingDate === null) {
        setDate(fetchedDate[0]);
        callback(fetchedDate[0]);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [fetchedDate, whichDate, callback, existingDate]);

  return (
    <FormControl variant="outlined" className={styles.formControl}>
      <InputLabel htmlFor="outlined-date-native-simple">{label}</InputLabel>
      {
        <Select
          native
          value={date}
          onChange={handleChange}
          label="Select"
          name="date"
          id="outlined-date-native-simple"
        >
          {fetchedDate.map((date, index) => (
            <option value={date} key={index}>
              {date}
            </option>
          ))}
        </Select>
      }
    </FormControl>
  );
}
