import React from 'react';
import {
  Paper,
  makeStyles,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { rem } from '../utils/styleHelpers';
import { colors } from '../utils/colors';
import classnames from 'classnames';
import Select from './Select';
import AreaChart from './Charts/AreaChart';
import BarChart from './Charts/BarChart';
import LeaderBoardChart from './Charts/LeaderBoardChart';
import { useState } from 'react';
import { useEffect } from 'react';
import SelectDates from './SelectDates';
import Fetch from '../utils/Fetch';
import { dashboardDates } from '../utils/endpoints';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'inline-flex',
      justifyContent: 'center',
    },
    grid: {
      width: rem(500),
      margin: rem(0),
    },
    paper: {
      height: rem(400),
      color: colors.white,
    },
    title: {
      margin: `${rem(10)}`,
    },
    mainChart: {
      marginBottom: rem(20),
    },
    loadingContainer: {
      width: '100%',
      textAlign: 'center',
    },
    loading: {
      marginTop: rem(100),
    },
    selectContariner: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'Dashboard' }
);

export default function Dashboard({ username, isAdmin }) {
  const styles = useStyles();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(username);
  const [fetchedDates, fetchLoading] = Fetch(dashboardDates, 'post', {}) || [];
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setName(username);
      if (username) setLoading(false);
      if (isAdmin) setName('Overview');
    }

    return () => {
      isMounted = false;
    };
  }, [username, isAdmin]);

  return (
    <div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress className={styles.loading}></CircularProgress>
        </div>
      ) : (
        <Grid container spacing={3} className={styles.root}>
          <Grid
            item
            xs={12}
            sm={8}
            className={classnames(styles.grid, styles.mainChart)}
          >
            <div className={styles.selectContariner}>
              {!fetchLoading && (
                <>
                  {' '}
                  <SelectDates
                    callback={setFrom}
                    whichDate={{ from: true, to: false }}
                    existingDate={from}
                    fetchedDate={fetchedDates}
                    label="From"
                  />
                  <SelectDates
                    callback={setTo}
                    whichDate={{ from: false, to: true }}
                    existingDate={to}
                    fetchedDate={fetchedDates}
                    label="To"
                  />{' '}
                </>
              )}
              {isAdmin ? <Select callback={setName} /> : null}
            </div>
            <Typography variant="h5" className={styles.title}>
              Weekly Performance Indicators
            </Typography>
            <Paper className={styles.paper}>
              {from !== null && to !== null && (
                <AreaChart from={from} to={to} name={name} />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={styles.grid}>
            <Typography variant="h5" className={styles.title}>
              Individual Ratings
            </Typography>
            <Paper className={styles.paper}>
              <BarChart name={name} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={styles.grid}>
            <Typography variant="h5" className={styles.title}>
              Leaderboard Ranking
            </Typography>
            <Paper className={styles.paper}>
              <LeaderBoardChart name={name} />
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
