import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  withStyles,
  makeStyles,
  Typography,
  Radio,
  Button,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { colors } from '../utils/colors';
import Tooltip from '../components/Tooltip';
import { hasToken } from '../utils/token';
import { rem } from '../utils/styleHelpers';

const useStyles = makeStyles(
  (theme) => ({
    feedbackContainer: {
      width: '100%',
    },
    feedbackParent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    feedbackItem: {
      width: '80%',
      minWidth: rem(400),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textArea: {
      width: '95%',
      marginTop: rem(40),
    },
    buttonParent: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: `${rem(20)} ${rem(0)}`,
    },
    button: {
      width: '95%',
    },
    agentSectionContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${rem(20)} ${rem(0)}`,
      '& < textarea': {
        width: '100%',
      },
    },
    radioContainer: {
      '& > span': {
        padding: rem(3),
      },
    },
  }),
  { name: 'Feedback' }
);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const tooltipStyle = {
  textAlign: 'right',
  cursor: 'pointer',
};

const tooltipTexts = {
  afn: 'Agents are expected to ask for the visitor\'s name within the first 3 responses in the chat. Note: The ideal response if the visitor says hi or hello = "Hi there! May I know who I am chatting with" OR if the visitor asks a question = "Let me check that for you, May I also know who I am chatting with"',
  acl: 'IF the visitor type was a suitable lead - agents are expected to ask for lead details within the chat. Note: Agent focus should always be on correctly identifying if the visitor is a useful lead to the client, and then asking for their details. This includes asking for details when the visitor is enquiring only. Agents are expected to attempt to turn an enquiry into a lead.',
  cld: 'Once the visitor has provided lead details the Agent is expected to CONFIRM the lead by using any of the shortcodes containing [name: phone: email:] within the chat.',
  tcc: 'IF it was required - Agents must correctly tag the chat with x-lead when the visitor contact details have been collected.',
  fms: 'Agents are expected to follow the mandatory question structure supplied in the FAQ for the client. Where no mandatory flow is provided in the FAQ - agents should ask for lead details of all visitors. Agents are expected to edit mandatory questions to fit the conversation.',
  upl: 'Agents are expected to use professional language at all times. Conversation should follow correct English language spelling and grammar flow. If a spelling mistake occurs, agents are expected to correct themselves within the chat. If a visitor says a statement that is happy or sad, agents are expected to respond appropriately for the tone. e.g Visitor: ’My father died” Agent: ’I’m sorry to hear that” - free typing is encouraged here',
  pss: 'Agents are expected to respond to all questions asked by the visitor. If the agent does not have the answer they must respond that they don’t have that information available. ',
  uvt: 'Agents are expected to be able to understand the context of the visitors conversation and identify if the visitor is a lead or not.',
  pcf: 'If a FAQ response is used the agent is expected to select the correct / most appropriate response. The agent is expected to edit the response to fit the conversation. Note: Agents should not paste large blocks of text unless all information is appropriate for the conversation. ',
};

export default function Feedback(props) {
  const {
    rated,
    rating,
    ratingSkills,
    // reason,
    // feedbackVia,
    feedbackCodes,
    feedbackText,
    agentFeedback,
  } = props.feedback;
  const isRated = false || rated;

  const styles = useStyles();

  const [AFNValue, setAFNValue] = useState('AFN#25');
  const [ACLValue, setACLValue] = useState('ACL#25');
  const [CLDValue, setCLDValue] = useState('CLD#25');
  const [TCCValue, setTCCValue] = useState('TCC#25');
  const [FMSValue, setFMSValue] = useState('FMS#20');
  const [UPLValue, setUPLValue] = useState('UPL#20');
  const [PSSValue, setPSSValue] = useState('PSS#20');
  const [UVTValue, setUVTValue] = useState('UVT#20');
  const [PCFValue, setPCFValue] = useState('PCF#20');

  const [loggedIn, setLoggedIn] = useState(false);

  const [feedbackNotes, setFeedbackNotes] = useState(feedbackText || '');
  const [submitMessage, setSubmitMessage] = useState(0);
  const [errorMessage, setErrorMessage] = useState(0);
  const [isAgent, setIsAgent] = useState(false);
  const [agentNotes, setAgentNotes] = useState('');
  const [user, setUsername] = useState('');
  const [userToken, setUserToken] = useState('');

  useEffect(() => {
    setLoggedIn(hasToken());
    if (loggedIn) {
      setUsername(localStorage.getItem('givenName'));
      setIsAgent(localStorage.getItem('superUser') !== 'true');
      setUserToken(localStorage.getItem('userToken'));

      const getCodes = () => {
        if (feedbackCodes) {
          const codes = feedbackCodes.split(',');
          setAFNValue(codes[0]);
          setACLValue(codes[1]);
          setCLDValue(codes[2]);
          setTCCValue(codes[3]);
          setFMSValue(codes[4]);
          setUPLValue(codes[5]);
          setPSSValue(codes[6]);
          setUVTValue(codes[7]);
          setPCFValue(codes[8]);
        }
      };
      getCodes();
    }
  }, [feedbackCodes, loggedIn, isAgent, isRated]);

  function calculateRating(string, type) {
    if (type === 'Key') {
      let total = 0;
      const array = string.split(/[a-zA-Z,]+#/g).splice(1, 4);
      array.forEach((number) => (total = total + Number(number)));
      return total;
    } else if (type === 'Skills') {
      let total = 0;
      const array = string.split(/[a-zA-Z,]+#/g).splice(5);
      array.forEach((number) => (total = total + Number(number)));
      return total;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const feedbackCodes = [
      AFNValue,
      ACLValue,
      CLDValue,
      TCCValue,
      FMSValue,
      UPLValue,
      PSSValue,
      UVTValue,
      PCFValue,
    ].join(',');

    const rating = calculateRating(feedbackCodes, 'Key');
    const ratingSkills = calculateRating(feedbackCodes, 'Skills');

    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/chats/update`,
        {
          livechatId: props.id,
          rated: true,
          feedbackCodes,
          rating,
          ratingSkills,
          feedbackText: feedbackNotes,
          ratedBy: user,
        },
        { headers: { 'x-access-token': userToken } }
      )
      .then((res) => {
        if (res.status === 201) {
          setSubmitMessage(100);
          setTimeout(() => setSubmitMessage(0), 2000);
          setTimeout(() => window.location.reload(true), 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(100);
        setTimeout(() => setErrorMessage(0), 2000);
        setTimeout(() => window.location.reload(true), 1000);
      });
  };

  function handleAgentNotes(event) {
    setAgentNotes(event.target.value);
  }

  function editFeedback() {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/chats/update`,
        {
          livechatId: props.id,
          rated: false,
        },
        { headers: { 'x-access-token': userToken } }
      )
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  }

  function handleAgentSubmit() {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/chats/update`,
        {
          livechatId: props.id,
          agentFeedback: agentNotes,
        },
        { headers: { 'x-access-token': userToken } }
      )
      .then((res) => {
        if (res.status === 201) {
          setSubmitMessage(100);
          setTimeout(() => setSubmitMessage(0), 2000);
          setTimeout(() => window.location.reload(true), 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(100);
        setTimeout(() => setErrorMessage(0), 2000);
        setTimeout(() => window.location.reload(true), 1000);
      });
  }

  const getRatingColor = (rating, type) => {
    if (type === 'rating') {
      if (rating === 0) return colors.gray4;
      if (rating === 100) return colors.success;
      if (rating === 75) return colors.warning;
      if (rating < 75) return colors.failure;
    }

    if (type === 'skill') {
      if (rating === 0) return colors.gray4;
      if (rating === 100) return colors.success;
      if (rating === 80) return colors.warning;
      if (rating < 80) return colors.failure;
    }
  };

  return (
    <div className={styles.feedbackContainer}>
      <form onSubmit={handleSubmit}>
        {/* <h3>{reason}</h3> */}
        {/* <h3>{feedbackVia}</h3> */}
        {rating ? (
          <Typography
            variant="h3"
            style={{
              color: getRatingColor(rating, 'rating'),
              textAlign: 'center',
              marginTop: rem(10),
            }}
          >
            {rating}%
          </Typography>
        ) : null}

        <div className={styles.feedbackParent}>
          <Typography variant="body2" style={{ marginBottom: rem(10) }}>
            Key Performance Indicator
          </Typography>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.afn}>
              <Typography variant="h5" style={tooltipStyle}>
                Asked for name at start
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              {' '}
              <RedRadio
                onChange={(event) => setAFNValue(event.target.value)}
                checked={AFNValue === 'AFN#0'}
                value="AFN#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setAFNValue(event.target.value)}
                checked={AFNValue === 'AFN#25'}
                value="AFN#25"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.acl}>
              <Typography variant="h5" style={tooltipStyle}>
                Asked visitor for lead details
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setACLValue(event.target.value)}
                checked={ACLValue === 'ACL#0'}
                value="ACL#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setACLValue(event.target.value)}
                checked={ACLValue === 'ACL#25'}
                value="ACL#25"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.cld}>
              <Typography variant="h5" style={tooltipStyle}>
                Confirmed lead details at first opportunity
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setCLDValue(event.target.value)}
                checked={CLDValue === 'CLD#0'}
                value="CLD#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setCLDValue(event.target.value)}
                checked={CLDValue === 'CLD#25'}
                value="CLD#25"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.tcc}>
              <Typography variant="h5" style={tooltipStyle}>
                Tagged chat correctly
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setTCCValue(event.target.value)}
                checked={TCCValue === 'TCC#0'}
                value="TCC#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setTCCValue(event.target.value)}
                checked={TCCValue === 'TCC#25'}
                value="TCC#25"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          {rating ? (
            <Typography
              variant="h3"
              style={{
                color: getRatingColor(ratingSkills, 'skill'),
                marginTop: rem(30),
              }}
            >
              {ratingSkills}%
            </Typography>
          ) : null}
          <Typography variant="body2" style={{ marginBotom: rem(10) }}>
            Communication Skills
          </Typography>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.fms}>
              <Typography variant="h5" style={tooltipStyle}>
                Followed mandatory chat flow structure
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setFMSValue(event.target.value)}
                checked={FMSValue === 'FMS#0'}
                value="FMS#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setFMSValue(event.target.value)}
                checked={FMSValue === 'FMS#20'}
                value="FMS#20"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.upl}>
              <Typography variant="h5" style={tooltipStyle}>
                Used correct and professional language
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setUPLValue(event.target.value)}
                checked={UPLValue === 'UPL#0'}
                value="UPL#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setUPLValue(event.target.value)}
                checked={UPLValue === 'UPL#20'}
                value="UPL#20"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.pss}>
              <Typography variant="h5" style={tooltipStyle}>
                Provided a suitable solution
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setPSSValue(event.target.value)}
                checked={PSSValue === 'PSS#0'}
                value="PSS#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setPSSValue(event.target.value)}
                checked={PSSValue === 'PSS#20'}
                value="PSS#20"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.uvt}>
              <Typography variant="h5" style={tooltipStyle}>
                Understood visitor context and type
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setUVTValue(event.target.value)}
                checked={UVTValue === 'UVT#0'}
                value="UVT#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setUVTValue(event.target.value)}
                checked={UVTValue === 'UVT#20'}
                value="UVT#20"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <div className={styles.feedbackItem}>
            <Tooltip title={tooltipTexts.pcf}>
              <Typography variant="h5" style={tooltipStyle}>
                Provided correct FAQ
              </Typography>
            </Tooltip>
            <div className={styles.radioContainer}>
              <RedRadio
                onChange={(event) => setPCFValue(event.target.value)}
                checked={PCFValue === 'PCF#0'}
                value="PCF#0"
                disabled={isRated || isAgent}
              />
              <GreenRadio
                onChange={(event) => setPCFValue(event.target.value)}
                checked={PCFValue === 'PCF#20'}
                value="PCF#20"
                disabled={isRated || isAgent}
              />
            </div>
          </div>
          <textarea
            onChange={(event) => setFeedbackNotes(event.target.value)}
            className={styles.textArea}
            rows="5"
            cols="50"
            value={feedbackNotes}
            placeholder={'Feedback/Comments'}
            disabled={isRated || isAgent}
          />
          <div className={styles.buttonParent}>
            {!isAgent && !isRated ? (
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                className={styles.button}
                type="submit"
              >
                Submit
              </Button>
            ) : null}
            {!isAgent && isRated ? (
              <Button
                onClick={editFeedback}
                variant="outlined"
                size="medium"
                color="primary"
                className={styles.button}
              >
                Edit
              </Button>
            ) : null}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {isRated ? (
              <div className={styles.agentSectionContainer}>
                <Typography variant="h6">Agent Comments</Typography>
                <textarea
                  onChange={handleAgentNotes}
                  style={{ width: '95%', marginTop: rem(10) }}
                  rows="5"
                  cols="50"
                  placeholder={agentFeedback || 'Agent Comments'}
                  disabled={agentFeedback || !isAgent}
                />
              </div>
            ) : null}

            {isRated && isAgent && !agentFeedback ? (
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                className={styles.margin}
                onClick={handleAgentSubmit}
                style={{ width: '100%' }}
              >
                Submit Comment
              </Button>
            ) : null}
          </div>
          <Typography
            variant="h6"
            style={{ opacity: submitMessage, color: colors.success }}
          >
            Chat Submitted
          </Typography>
          <Typography
            variant="h6"
            style={{ opacity: errorMessage, color: colors.failure }}
          >
            Oops please try again!
          </Typography>
        </div>
      </form>
    </div>
  );
}
