import React, { useState } from 'react';
import axios from 'axios';

export default function Fetch(url, method, data, headers = null) {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    setLoading(true);
    setResponse(null);
    setError(null);

    axios({ method, url, data, headers })
      .then((res) => {
        setResponse(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          localStorage.setItem('sessionExpired', 'true');
        }
        setError(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.agentName, data.from, data.to]);

  return [response, loading, error];
}
