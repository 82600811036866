import React from 'react';
import { makeStyles, useTheme, CircularProgress } from '@material-ui/core';
import {
  BarChart as ReBarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
} from 'recharts';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/styleHelpers';
import { getTootltipTitle } from '../../utils/codeDescriptions';
import Tooltip from '../../components/Tooltip';
import Fetch from '../../utils/Fetch';
import { codes } from '../../utils/endpoints';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
    },
    legendText: {
      color: colors.gray3,
      verticalAlign: 'middle',
      marginRight: rem(5),
    },
    loading: {
      marginTop: '20%',
    },
  }),
  { name: 'AreaChart' }
);

export default function BarChart({ from, to, name }) {
  const styles = useStyles();
  const theme = useTheme();
  const barSize = 50;

  const [codeRatingsRes, loading] = Fetch(
    codes,
    'post',
    { agentName: name },
    { 'x-access-token': localStorage.getItem('userToken') }
  );

  const codeRatings = codeRatingsRes || [];

  const getLegendText = (value, entry) => {
    return (
      <Tooltip title={getTootltipTitle(value)}>
        <span className={styles.legendText}>
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </span>
      </Tooltip>
    );
  };

  return (
    <div className={styles.root}>
      {loading ? (
        <CircularProgress className={styles.loading}></CircularProgress>
      ) : (
        <ResponsiveContainer width={'95%'} height={'95%'}>
          <ReBarChart
            data={codeRatings}
            margin={{
              top: 40,
              right: 30,
              left: 0,
              bottom: 5,
            }}
            barGap={8}
          >
            <CartesianGrid vertical={false} stroke={colors.gray5} />
            <XAxis
              dataKey="0"
              axisLine={false}
              tickLine={false}
              tickMargin={17}
              stroke={colors.gray4}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              domain={[20, 100]}
              stroke={colors.gray4}
            />
            <Bar
              dataKey="AFN"
              fill={theme.palette.primary.main}
              barSize={barSize}
            />
            <Bar dataKey="ACL" fill={colors.red} barSize={barSize} />
            <Bar dataKey="CLD" fill={colors.blue} barSize={barSize} />
            <Bar dataKey="FMS" fill={'#9b5094'} barSize={barSize} />
            <Bar dataKey="PCF" fill={colors.gray3} barSize={barSize} />
            <Bar dataKey="PSS" fill={colors.yellow} barSize={barSize} />
            <Bar dataKey="TCC" fill={'#B4ADEA'} barSize={barSize} />
            <Bar dataKey="UPL" fill={colors.turquoise} barSize={barSize} />
            <Bar dataKey="UVT" fill={'#F2DFD7'} barSize={barSize} />
            <Legend
              formatter={getLegendText}
              align="right"
              height={30}
              iconSize={15}
              iconType="circle"
            />
          </ReBarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
