import React from "react";
import { makeStyles } from "@material-ui/core";
import { rem } from "../utils/styleHelpers";

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: rem(50),
      marginTop: rem(100),
      marginLeft: rem(100),
    },
  },
  { name: "Container" }
);

export default function Container({ children }) {
  const styles = useStyles();
  return <div className={styles.root}>{children}</div>;
}
