import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { hasToken } from '../utils/token';

export default function DeleteButton(props) {
  const [goToChats, setGoToChats] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    setLoggedIn(hasToken());
    if (loggedIn) {
      setUserToken(localStorage.getItem('userToken'));
      const getUserType = async () => {
        const superUser = localStorage.getItem('superUser') === 'true';
        if (!superUser) {
          setIsAgent(true);
        }
      };
      if (userToken !== null) getUserType();
    }
  }, [userToken, loggedIn]);

  const deleteItem = () => {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/chats/${props.id}`, {
        headers: { 'x-access-token': userToken },
      })
      .then((res) => {
        if (res.data.message === 'Chat deleted successfully') {
          setGoToChats(true);
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err));
  };

  return (
    <div>
      {goToChats ? <Redirect to="/chats" /> : null}
      <Button
        style={{ marginTop: '1rem', width: '100%' }}
        variant="outlined"
        color="secondary"
        onClick={deleteItem}
        disabled={isAgent}
      >
        Delete
      </Button>
    </div>
  );
}
