import React from 'react';
import {
  makeStyles,
  useTheme,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import {
  LineChart,
  ResponsiveContainer,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/styleHelpers';
import Fetch from '../../utils/Fetch';
import { leaderboard } from '../../utils/endpoints';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      height: 'inherit',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
    },
    legendText: {
      color: colors.gray3,
      verticalAlign: 'middle',
    },
    loading: {
      marginTop: '20%',
    },
    leaderboardContainer: {
      display: 'flex',
      flexFlow: 'column wrap',
      justifyContent: 'center',
      width: '95%',
      height: '95%',
      padding: `${rem(0)} ${rem(20)}`,
    },
    leaderboardItem: {
      display: 'flex',
      alignItems: 'center',
      padding: rem(5),
      margin: rem(5),
      borderRadius: rem(5),
      backgroundColor: colors.gray3,
    },
    avatar: {
      width: rem(25),
      height: rem(25),
      borderRadius: rem(5),
      border: `2px solid ${theme.palette.primary.light}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: rem(5),
    },
    leaderboardText: {
      color: colors.white,
    },
  }),
  { name: 'AreaChart' }
);

const getColor = (rating) => {
  if (rating <= 3) return colors.lime;
  if (rating >= 15) return colors.failure;
  return colors.warning;
};

export default function LeaderboardChart({ name }) {
  const styles = useStyles();
  const theme = useTheme();

  const [leaderboardRes, loading] = Fetch(
    leaderboard,
    'post',
    {
      agentName: name,
    },
    { 'x-access-token': localStorage.getItem('userToken') }
  );

  const leaderboardRatings = leaderboardRes || [];

  const getLegendText = (value, entry) => {
    return <span className={styles.legendText}>Leaderboard Ranking</span>;
  };

  if (name === 'Overview') {
    return (
      <div className={styles.root}>
        {loading ? (
          <CircularProgress className={styles.loading} />
        ) : (
          <div className={styles.leaderboardContainer}>
            {leaderboardRatings.map(({ name, leaderboardRating }, index) => (
              <div className={styles.leaderboardItem} key={index}>
                <div
                  className={styles.avatar}
                  style={{ borderColor: getColor(leaderboardRating) }}
                >
                  {leaderboardRating}
                </div>
                <Typography
                  variant="caption"
                  className={styles.leaderboardText}
                >
                  {name}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {loading ? (
        <CircularProgress className={styles.loading} />
      ) : (
        <ResponsiveContainer width={'95%'} height={'95%'}>
          <LineChart
            data={leaderboardRatings}
            margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 0"
              vertical={false}
              stroke={colors.gray5}
            />
            <XAxis
              dataKey="ratingDate"
              axisLine={false}
              tickLine={false}
              tickMargin={17}
              stroke={colors.gray4}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              reversed={true}
              type="number"
              domain={[1, 20]}
              stroke={colors.gray4}
            />
            <Tooltip
              cursor={{ strokeWidth: 0 }}
              formatter={(value, name) => [value, 'Ranking']}
              itemStyle={{
                color: colors.lime,
                padding: rem(0),
              }}
              contentStyle={{ padding: rem(5) }}
              labelStyle={{ color: colors.gray3 }}
            />
            <Legend
              formatter={getLegendText}
              verticalAlign="top"
              align="right"
              height={30}
              iconSize={15}
              iconType="circle"
            />
            <Line
              type="linear"
              dataKey="leaderboardRating"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
