import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import {
  CircularProgress,
  makeStyles,
  Typography,
  Paper,
} from '@material-ui/core';
import Header from '../components/Header';
import Feedback from '../components/Feedback';
import Page from '../components/Page';
import Container from '../components/Container';
import DeleteButton from '../components/DeleteButton';
import PrevNextButton from '../components/PrevNextButton';
import { hasToken } from '../utils/token';
import { clearLocalStorage } from '../utils/localStorage';
import { rem } from '../utils/styleHelpers';
import { colors } from '../utils/colors';

const useStyles = makeStyles(
  (theme) => ({
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      justifyContent: 'center',
      padding: rem(20),
      width: '40%',
      margin: rem(10),
    },
    chatColumnContainer: {},
    chatDetails: {
      display: 'flex',
      marginLeft: rem(30),
      marginBottom: rem(10),
    },
    chatDetailTitle: {
      textAlign: 'right',
      marginRight: rem(10),
      '& > h6': {
        color: colors.gray3,
      },
    },
    chatContainer: {
      backgroundColor: colors.gray2,
      padding: `${rem(20)} ${rem(5)}`,
      borderRadius: '5px',
    },
    chatParent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginBottom: rem(15),
    },
    chatText: {
      color: colors.gray5,
      width: '75%',
    },
    loading: {
      marginTop: rem(100),
    },
  }),
  { name: 'Chat' }
);

export default function Chat() {
  const styles = useStyles();
  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const chatId = window.location.pathname.split('/')[2];
  const [chat, setChat] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [loading, setloading] = useState(true);
  const [toHome, setToHome] = useState(false);
  const [chatNotFound, setChatNotFound] = useState(true);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    let isMounted = true;

    setLoggedIn(hasToken());
    if (loggedIn) {
      setIsAdmin(localStorage.getItem('superUser'));
      setUserToken(localStorage.getItem('userToken'));
      clearLocalStorage();

      const getChat = async () => {
        setloading(true);
        await axios
          .get(`${process.env.REACT_APP_SERVER_URL}/api/chats/${chatId}`, {
            headers: { 'x-access-token': userToken },
          })
          .then((res) => {
            if (isMounted) {
              setChatNotFound(false);
              setChat(res.data.livechatDetails);
              setFeedback(res.data.chat);
              setloading(false);
            }
          })
          .catch(() => {
            setChatNotFound(true);
            setloading(false);
            setToHome(true);
          });
      };
      if (userToken !== null) getChat();
    }
    if (!hasToken()) setToHome(true);

    return () => {
      isMounted = false;
    };
  }, [chatId, loggedIn, userToken]);

  return (
    <Page>
      {toHome ? <Redirect to="/" /> : null}
      <Header loggedIn={loggedIn} isAdmin={isAdmin} />
      <Container>
        <PrevNextButton />
        {loading ? (
          <CircularProgress className={styles.loading} />
        ) : (
          <div>
            {chatNotFound ? (
              <Typography variant="h4">Chat Not Found</Typography>
            ) : (
              <div className={styles.contentContainer}>
                <Paper className={styles.paper}>
                  <div className={styles.chatColumnContainer}>
                    <div className={styles.chatDetails}>
                      <div className={styles.chatDetailTitle}>
                        <Typography variant="body2">ID: </Typography>
                        <Typography variant="body2">Client: </Typography>
                        <Typography variant="body2">Date: </Typography>
                        <Typography variant="body2">FAQ: </Typography>
                        <Typography variant="body2">Tags: </Typography>
                        <Typography variant="body2">Rated By: </Typography>
                        <Typography variant="body2">Reason: </Typography>
                      </div>
                      <div>
                        <Typography variant="body2">{chat.id}</Typography>
                        <Typography variant="body2">
                          {chat.custom_variables[0]
                            ? chat.custom_variables[0].value
                            : null}
                        </Typography>
                        <Typography variant="body2">{chat.ended}</Typography>
                        <Typography variant="body2">
                          <a
                            href={
                              chat.custom_variables[4]
                                ? chat.custom_variables[4].value
                                : null
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            FAQ page
                          </a>
                        </Typography>
                        <Typography variant="body2">
                          {!chat.tags.length
                            ? 'No Tags'
                            : chat.tags.map((tag) => tag)}
                        </Typography>
                        <Typography variant="body2">
                          {feedback.ratedBy || 'Not Rated'}
                        </Typography>
                        <Typography variant="body2">
                          {feedback.reason}
                        </Typography>
                      </div>
                    </div>
                    <div className={styles.chatContainer}>
                      {chat
                        ? chat.messages &&
                          chat.messages.map((message, index) => {
                            return (
                              <div key={index} className={styles.chatParent}>
                                <Typography variant="body2">
                                  {message.author_name}:
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className={styles.chatText}
                                >
                                  {message.text}
                                </Typography>
                              </div>
                            );
                          })
                        : setToHome(true)}
                    </div>
                    <DeleteButton id={chatId} />
                  </div>
                </Paper>
                <Paper className={styles.paper}>
                  <Feedback id={chat.id} feedback={feedback} />
                </Paper>
              </div>
            )}
          </div>
        )}
      </Container>
    </Page>
  );
}
