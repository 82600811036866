import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { rem } from "../utils/styleHelpers";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    padding: rem(10),
    lineHeight: 1.5,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

export default function Tooltip({ children, title }) {
  return (
    <LightTooltip placement="top" title={title}>
      {children}
    </LightTooltip>
  );
}
