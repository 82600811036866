import React, { useState, useEffect } from 'react';
import SigninForm from '../components/SigninForm';
import { makeStyles, CircularProgress } from '@material-ui/core';
import Page from '../components/Page';
import Header from '../components/Header';
import Container from '../components/Container';
import { rem } from '../utils/styleHelpers';
import { hasToken } from '../utils/token';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(
  {
    content: {
      display: 'flex',
      justifyContent: 'center',
      margin: rem(50),
      marginTop: rem(100),
    },
    login: {
      width: 'max-content',
      marginTop: rem(150),
      outline: 'none',
    },
    pageTitle: {
      width: '100%',
    },
    welcomeText: {
      marginLeft: rem(10),
    },
    loading: {
      width: '100%',
      textAlign: 'center',
      marginTop: rem(150),
    },
  },
  { name: 'Home' }
);

export default function AddUser() {
  const styles = useStyles();

  const [loggedIn, setLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [goHome, setGoHome] = useState(false);

  useEffect(() => {
    setLoggedIn(hasToken());
    setIsAdmin(localStorage.getItem('superUser') === 'true');
    if (!hasToken()) setGoHome(true);
    setLoading(false);
  }, [loggedIn]);

  return (
    <div>
      <Page>
        {goHome ? <Redirect to="/" /> : null}
        <Header loggedIn={loggedIn} isAdmin={isAdmin} />
        {loading ? (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          <Container>
            {loggedIn ? (
              <div>
                <SigninForm />
              </div>
            ) : (
              <div className={styles.pageTitle}></div>
            )}
          </Container>
        )}
      </Page>
    </div>
  );
}
