import React from "react";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import { rem } from "../utils/styleHelpers";
import { colors } from "../utils/colors";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: `${0} ${rem(10)}`,
      borderRadius: rem(5),
      "&:hover": {
        boxShadow: "0px 0px 25px lightgrey",
      },
    },
    nameAvatar: {
      width: rem(5),
      height: rem(5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `3px solid  ${colors.gray4}`,
      padding: rem(20),
      margin: rem(10),
      marginTop: rem(16),
      borderRadius: rem(5),
    },
    details: {
      display: "flex",
      flexDirection: "row",
      marginLeft: rem(10),
    },
    textContainer: {
      maxWidth: rem(100),
      margin: rem(20),
    },
    title: {
      fontSize: rem(12),
      marginBottom: rem(5),
      color: colors.gray4,
    },
    divider: {
      margin: `${rem(0)} ${rem(20)}`,
    },
  }),
  { name: "ChatItem" }
);

export default function ChatItem({
  agent,
  rating,
  id,
  group,
  date,
  ratingColor,
}) {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.root}>
        <div className={styles.nameAvatar} style={{ borderColor: ratingColor }}>
          <Typography variant="body2" style={{ color: ratingColor }}>
            {rating}
          </Typography>
        </div>
        <div className={styles.details}>
          <div className={styles.textContainer}>
            <Typography variant="body2" className={styles.title}>
              Agent
            </Typography>
            <Typography variant="h6" id="agent">
              {agent}
            </Typography>
          </div>
          <div className={styles.textContainer}>
            <Typography variant="body2" className={styles.title}>
              Date
            </Typography>
            <Typography variant="h6" id="date">
              {date}
            </Typography>
          </div>
          <div className={styles.textContainer}>
            <Typography variant="body2" className={styles.title}>
              Chat ID
            </Typography>
            <Typography variant="h6" id="id">
              {id}
            </Typography>
          </div>
          <div className={styles.textContainer}>
            <Typography variant="body2" className={styles.title}>
              Group
            </Typography>
            <Typography variant="h6" id="group">
              {group}
            </Typography>
          </div>
        </div>
      </div>
      <Divider className={styles.divider} />
    </div>
  );
}
