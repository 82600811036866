import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";

Sentry.init({
	dsn: "https://c6cc399fade54653acb63e98cdb94eec@sentry.io/1731263",
});

ReactDOM.render(<App />, document.getElementById("root"));
