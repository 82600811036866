import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Home from './views/Home';
import AddChat from './views/AddChat';
import AllChats from './views/AllChats';
import Chat from './views/Chat';
import AddUser from './views/AddUser';
import EditUser from './views/EditUser';

export default function Routes({ loggedIn, isAdmin }) {
  if (!loggedIn) {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    );
  }

  if (loggedIn && !isAdmin) {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/chats" component={AllChats} />
        <Route exact path="/chats/:id" component={Chat} />
        <Redirect to="/" />
      </Switch>
    );
  }

  if (loggedIn && isAdmin) {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/addchat" component={AddChat} />
        <Route exact path="/chats" component={AllChats} />
        <Route exact path="/chats/:id" component={Chat} />
        <Route exact path="/signin" component={AddUser} />
        <Route exact path="/editUser" component={EditUser} />
        <Redirect to="/" />
      </Switch>
    );
  }
}
