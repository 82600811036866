import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { changePass } from '../utils/user';
import {
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  btn: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
  },
});

export default function ChangePassForm(props) {
  const classes = useStyles();

  const [oldPass, setOldPass] = useState('');
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (oldPass !== '' && password !== '' && confirm !== '') {
      setDisabled(false);
    }

    if (password !== confirm && confirm !== '') {
      setError(true);
      setDisabled(true);
      setHelperText('Passwords must match!');
      return;
    }

    setError(false);
    setHelperText('');
    return () => {
      //   console.log();
    };
  }, [password, confirm, oldPass]);

  const handleSubmit = async () => {
    if (error) return;
    const user = {
      oldPass,
      password,
    };
    const success = await changePass(user, localStorage.getItem('userToken'));
    if (success) {
      setSuccess(true);
    } else {
      setFailed(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
    setFailed(false);
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Change Password Form
          </Typography>

          <div>
            <TextField
              type="password"
              label="Old Password"
              className={classes.input}
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              variant="outlined"
            />
          </div>
          <div>
            <TextField
              type="password"
              label="New Password"
              className={classes.input}
              value={password}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <TextField
              type="password"
              className={classes.input}
              value={confirm}
              error={error}
              label="Confirm Password"
              variant="outlined"
              helperText={helperText}
              onChange={(e) => setConfirm(e.target.value)}
            />
          </div>
        </CardContent>
        <CardActions>
          <Button
            disabled={disabled}
            onClick={async () => await handleSubmit()}
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            Change password
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        open={success}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success">
          Password changed succesfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failed}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error">
          Failed to change password!
        </Alert>
      </Snackbar>
    </div>
  );
}
